module.exports = [
  {
    products_id: 1,
    product: "CCS footcare cream",
    type: "175ml",
    price: "£8.00",
  },
  { products_id: 2, product: "", type: "60ml", price: "£4.00" },
  { products_id: 3, product: "+ warming agent", type: "", price: "£9.50" },
  { products_id: 4, product: "Lamisil", type: "cream", price: "£6.50" },
  { products_id: 5, product: "", type: "spray", price: "£5.50" },
  { products_id: 6, product: "Clotrimiazole", type: "cream", price: "£4.50" },
  {
    products_id: 7,
    product: "Orthoses",
    type: "simple (from)",
    price: "£25.00",
  },
  {
    products_id: 8,
    product: null,
    type: "customisable chairside (from)",
    price: "£80.00",
  },
  {
    products_id: 9,
    product: "Edsar Labs Orthoses (bespoke from a foot scan)",
    type: "high performance (from)",
    price: "£300.00",
  },
  {
    products_id: 10,
    product: "",
    type: "components (i.e. heel raise, from)",
    price: "£5.00",
  },
  {
    products_id: 11,
    product: "Poron pink memory insoles",
    type: "single (from)",
    price: "£15.00",
  },
  { products_id: 12, product: "", type: "pair (from)", price: "£30.00" },
  {
    products_id: 13,
    product: "Evazote thermal insoles",
    type: "per pair",
    price: "£10.00",
  },
  {
    products_id: 14,
    product: "Silopad washable toe protectors",
    type: "(from)",
    price: "£4.00",
  },
  {
    products_id: 15,
    product: "Bamboo socks",
    type: "pair (from)",
    price: "£1.99",
  },
  {
    products_id: 16,
    product: "Precious pedis ceramic callus file",
    type: "",
    price: "£12.00",
  },
  {
    products_id: 17,
    product: "Glass nail file with case",
    type: "",
    price: "£4.99",
  },
];
